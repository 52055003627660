import { cn } from '@hapstack/common'
import type { ComponentProps } from 'react'

export const AppLayout = ({ className, children }: ComponentProps<'div'>) => {
  return (
    <div className={cn('flex h-full min-h-screen bg-muted/40', className)}>
      {children}
    </div>
  )
}
